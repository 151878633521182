import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl w-full bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Política de Privacidad</h1>
        
        <p className="text-gray-700 mb-4">
          En <strong>BMR</strong>, nos tomamos muy en serio la privacidad y seguridad de nuestros usuarios. 
          Esta Política de Privacidad explica cómo recopilamos, utilizamos, almacenamos y protegemos tu información personal cuando utilizas nuestra aplicación.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Información que Recopilamos</h2>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            <strong>Datos de la cuenta:</strong> Nombre, correo electrónico e imagen de perfil, obtenidos al iniciar sesión mediante Google, Apple o al registrarte con correo electrónico y contraseña.
          </li>
          <li>
            <strong>Interacciones:</strong> Preferencias de alarmas, actividad en el blog (como "likes") y listas de reproducción seleccionadas.
          </li>
        </ul>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Cómo Usamos la Información</h2>
        <p className="text-gray-700 mb-4">
          Utilizamos los datos recopilados para autenticar tu acceso, personalizar tu experiencia, enviar notificaciones positivas y facilitar el acceso al contenido. 
          <strong> No compartimos tu información personal con terceros.</strong>
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Seguridad de la Información</h2>
        <p className="text-gray-700 mb-4">
          Implementamos medidas de seguridad apropiadas para proteger tu información frente a accesos no autorizados. 
          Todos los datos se almacenan en Firebase con estándares de seguridad avanzados.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Suscripciones y Pagos</h2>
        <p className="text-gray-700 mb-4">
          Para acceder a <strong>BMR</strong>, necesitas adquirir una suscripción mensual, trimestral o anual mediante 
          <strong> In-App Purchase</strong> en Android o iOS. Las transacciones son gestionadas directamente por Google Play y Apple Store; no almacenamos información financiera.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Eliminación de Cuenta y Datos</h2>
        <ol className="list-decimal list-inside text-gray-700 mb-4">
          <li>Cancela tu suscripción en Google Play o Apple Store.</li>
          <li>Accede a la sección <strong>"Perfil"</strong> dentro de la aplicación.</li>
          <li>Sigue las instrucciones para eliminar tu cuenta. Esto eliminará tus datos personales de nuestros servidores.</li>
        </ol>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Retención de Datos</h2>
        <p className="text-gray-700 mb-4">
          Una vez eliminada tu cuenta, tus datos se eliminarán de nuestros sistemas salvo que sea necesario retenerlos por razones legales o de auditoría.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Cambios en esta Política</h2>
        <p className="text-gray-700 mb-4">
          Podemos actualizar esta política ocasionalmente. Te notificaremos de cualquier cambio importante. 
          Te recomendamos revisar esta página periódicamente.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Contacto</h2>
        <p className="text-gray-700 mb-4">
          Si tienes preguntas o inquietudes, contáctanos a través del correo 
          <a href="mailto:mentesmillonariasdehoy@gmail.com" className="text-indigo-600 hover:text-indigo-800"> mentesmillonariasdehoy@gmail.com</a>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;