import React from 'react';

function TermsAndConditions() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl w-full bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Términos y Condiciones</h1>
        
        <p className="text-gray-700 mb-4">
          Bienvenido a <strong>BMR</strong>. Al usar nuestra aplicación, aceptas cumplir con los siguientes Términos y Condiciones. Por favor, léelos detenidamente antes de usar nuestros servicios.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">1. Uso de la Aplicación</h2>
        <p className="text-gray-700 mb-4">
          <strong>BMR</strong> está diseñado para ayudarte a alcanzar tus metas financieras y personales. El uso indebido de la aplicación, como intentos de acceso no autorizado, distribución de contenido inapropiado o uso con fines ilegales, está estrictamente prohibido.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">2. Registro y Acceso</h2>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Para utilizar la aplicación, debes registrarte utilizando un correo electrónico válido, Google o Apple ID.</li>
          <li>Es tu responsabilidad mantener la confidencialidad de tus credenciales de inicio de sesión.</li>
          <li>Nos reservamos el derecho de suspender cuentas por incumplimiento de estos términos.</li>
        </ul>

        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">3. Suscripciones y Pagos</h2>
        <p className="text-gray-700 mb-4">
          El acceso a <strong>BMR</strong> requiere una suscripción mensual, trimestral o anual. Los pagos se procesan a través de Google Play o Apple Store. No ofrecemos reembolsos, excepto cuando lo exija la ley.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">4. Propiedad Intelectual</h2>
        <p className="text-gray-700 mb-4">
          Todo el contenido, diseño y funcionalidades de <strong>BMR</strong> están protegidos por leyes de propiedad intelectual. No puedes copiar, modificar, distribuir ni utilizar nuestro contenido sin nuestro consentimiento expreso.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">5. Limitación de Responsabilidad</h2>
        <p className="text-gray-700 mb-4">
          Aunque nos esforzamos por brindar un servicio confiable, <strong>BMR</strong> no garantiza que la aplicación esté libre de errores o interrupciones. No somos responsables por pérdidas derivadas del uso de la aplicación.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">6. Cambios en los Términos</h2>
        <p className="text-gray-700 mb-4">
          Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Te notificaremos sobre cambios importantes. El uso continuo de la aplicación después de tales modificaciones implica tu aceptación de los términos actualizados.
        </p>
        
        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">7. Terminación del Servicio</h2>
        <p className="text-gray-700 mb-4">
          Podemos suspender o terminar tu acceso a <strong>BMR</strong> en cualquier momento si consideramos que has violado estos términos.
        </p>

        <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">8. Contacto</h2>
        <p className="text-gray-700 mb-4">
          Si tienes preguntas o inquietudes sobre estos términos, contáctanos en 
          <a href="mailto:mentesmillonariasdehoy@gmail.com" className="text-indigo-600 hover:text-indigo-800"> mentesmillonariasdehoy@gmail.com</a>.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;