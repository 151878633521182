import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

function PrivateRoute() {
  const [user, loading] = useAuthState(auth);

  // Si el estado de autenticación aún se está cargando, muestra un indicador de carga.
  if (loading) {
    return <div>Cargando...</div>;  // Puedes reemplazar esto con un spinner o un componente de carga personalizado.
  }

  // Si el usuario está autenticado, renderiza el contenido de la ruta privada.
  return user ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
