'use client'
import logo from '../logo.png'

import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';


import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuItem,
    Menu,
    MenuButton,
    MenuItems,
} from '@headlessui/react'

import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Inicio', href: '/' },
    { name: 'Política de privacidad', href: '/privacy-policy' },
    { name: 'Términos y condiciones', href: '/terms-and-conditions' },
]

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}


export default function Navbar() {
    const [user, loading] = useAuthState(auth);
    const [prevUser, setPrevUser] = useState(user);

    useEffect(() => {
        if (!loading) {
            setPrevUser(user);
        }
    }, [loading, user]);

    const location = useLocation();

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error('Error al cerrar sesión', error);
        }
    };

    return (
        <Disclosure as="nav" className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* Mobile menu button*/}
                        <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                            <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                        </DisclosureButton>
                    </div>
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex flex-shrink-0 items-center">
                            
                            <span className="ml-2 text-white font-semibold text-xl">BMR</span>
                        </div>
                        <div className="hidden sm:ml-6 sm:block">
                            <div className="flex space-x-4">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        aria-current={location.pathname === item.href ? 'page' : undefined}
                                        className={classNames(
                                            location.pathname === item.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'rounded-md px-3 py-2 text-sm font-medium',
                                        )}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        {prevUser ? (
                            // Profile dropdown
                            <Menu as="div" className="relative ml-3">
                                <div>
                                    <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span className="absolute -inset-1.5" />
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            alt="User profile"
                                            src={prevUser.photoURL || 'https://via.placeholder.com/40'}
                                            className="h-8 w-8 rounded-full"
                                        />
                                    </MenuButton>
                                </div>
                                <MenuItems
                                    transition
                                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <MenuItem>
                                        <Link to="/account-data" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            Datos de la cuenta
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <button
                                            onClick={handleSignOut}
                                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Cerrar sesión
                                        </button>
                                    </MenuItem>
                                </MenuItems>
                            </Menu>
                        ) : (
                            <Link
                                to="/login"
                                className="text-sm font-medium bg-gray-100 rounded-md px-3 py-2 text-sm font-medium text-gray-800 sm:block hidden"
                            >
                                Iniciar sesión
                            </Link>
                        )}
                    </div>
                </div>
            </div>

            <DisclosurePanel className="sm:hidden">

                <div className="space-y-1 px-2 pb-3 pt-2">
                    {navigation.map((item) => (
                        <DisclosureButton
                            key={item.name}
                            as={Link}
                            aria-current={location.pathname === item.href ? 'page' : undefined}
                            to={item.href}
                            className={classNames(
                                location.pathname === item.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'block rounded-md px-3 py-2 text-base font-medium',
                            )}
                        >
                            {item.name}
                        </DisclosureButton>
                    ))}
                    <div className={user ? "hidden" : "block"}>
                        <div className="border-t border-gray-700 my-5 mx-2" />
                        <DisclosureButton
                            as={Link}
                            to="/login"
                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                        >
                            Iniciar Sesión
                        </DisclosureButton>
                    </div>
                </div>

            </DisclosurePanel>
        </Disclosure>
    )
}
