import React from 'react';
import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'

function Home() {
    return (
        <div className="bg-white">
            <div className="bg-gray-900 text-white">
                <header className="px-6 text-center lg:py-20 py-10">
                    <div className="container mx-auto">
                        <h1 className="text-3xl font-bold">BMR</h1>
                        <p className="mt-2 text-lg">Transforma tu vida con el poder de la autosugestión positiva.</p>
                    </div>
                </header>
                <div className="relative isolate overflow-hidden py-16 px-16 sm:py-24 lg:py-16 max-w-7xl items-center justify-between mx-auto">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:pt-2">
                        <div className="flex flex-col items-start lg:items-center lg:flex-row space-y-1 lg:space-x-4">
                            <div className="h-full flex items-center rounded-md bg-white/5 p-2 ring-1 ring-white/10 lg:px-8">
                                <span className="text-3xl">🚀</span>
                            </div>
                            <div className="flex flex-col items-start">
                                <dt className="mt-4 font-semibold text-white">Empoderamiento Constante</dt>
                                <dd className="mt-2 leading-7 text-gray-400">
                                    Recibe mensajes motivacionales que te mantienen enfocado en tus metas de éxito y abundancia, como lo hacen los millonarios.
                                </dd>
                            </div>
                        </div>
                        <div className="flex flex-col items-start lg:items-center lg:flex-row space-y-1 lg:space-x-4">
                            <div className="h-full flex items-center rounded-md bg-white/5 p-2 ring-1 ring-white/10 lg:px-8">
                                <span className="text-3xl">🎯</span>
                            </div>
                            <div className="flex flex-col items-start">
                                <dt className="mt-4 font-semibold text-white">Empoderamiento Constante</dt>
                                <dd className="mt-2 leading-7 text-gray-400">
                                    Crea tus propias afirmaciones o sube audios personales para recibir recordatorios automáticos que refuercen tu mentalidad positiva.
                                </dd>
                            </div>
                        </div>
                        <div className="flex flex-col items-start lg:items-center lg:flex-row space-y-1 lg:space-x-4">
                            <div className="h-full flex items-center rounded-md bg-white/5 p-2 ring-1 ring-white/10 lg:px-8">
                                <span className="text-3xl">💭</span>
                            </div>
                            <div className="flex flex-col items-start">
                                <dt className="mt-4 font-semibold text-white">Empoderamiento Constante</dt>
                                <dd className="mt-2 leading-7 text-gray-400">
                                    Elimina creencias limitantes y adopta la mentalidad de éxito de los millonarios, manteniendo una actitud ganadora cada día.
                                </dd>
                            </div>
                        </div>
                        <div className="flex flex-col items-start lg:items-center lg:flex-row space-y-1 lg:space-x-4">
                            <div className="h-full flex items-center rounded-md bg-white/5 p-2 ring-1 ring-white/10 lg:px-8">
                                <span className="text-3xl">🤝</span>
                            </div>
                            <div className="flex flex-col items-start">
                                <dt className="mt-4 font-semibold text-white"> Comunidad de Exitosos</dt>
                                <dd className="mt-2 leading-7 text-gray-400">
                                    Conecta con personas en el camino del crecimiento personal y financiero, comparte experiencias y celebra cada logro.
                                </dd>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
            <section className="text-center p-16">
                <h2 className="text-xl font-semibold text-gray-900">¡Empieza Hoy!</h2>
                <p className="mt-4 text-gray-600">Descarga BMR y comienza a transformar tu vida con el poder de la autosugestión positiva.</p>
                <div className="mt-8">
                    <a href="https://play.google.com/store/apps/details?id=com.mm.mente_millonaria" className="inline-block bg-indigo-600 text-white py-2 px-6 rounded-full hover:bg-indigo-500 transition">
                        Descargar la App
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Home;
